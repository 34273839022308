import { ApolloLink } from '@apollo/client';
import { mainClient } from 'apollo/client';
import { createAuthenticationLink, errorLink, mainLink } from 'apollo/link';
import Error404 from 'components/desktop/error-404';
import MobileError404 from 'components/mobile/error-404';
import { AuthenticationModel } from 'components/providers/authentication-provider';
import { DEFAULT_DESCRIPTION, DEFAULT_OG_IMAGE, DEFAULT_TITLE, MAIN_DOMAIN } from 'globalConstants';
import {
  ApprovalStatusEnumType,
  Direction,
  GET_REAL_ESTATES,
  GetRealEstatesData,
  GetRealEstatesVariables,
  ListRealEstate,
  PostStatusType,
  TradingStatus,
} from 'graphql/main/queries/get-real-estates';
import {
  NEAR_REAL_ESTATES,
  NearRealEstatesData,
  NearRealEstatesVariables,
  StatisticRealEstate,
} from 'graphql/main/queries/near-real-estates';
import { SLUGIFY, Slug, SlugifyData, SlugifyVariables } from 'graphql/main/queries/slugify';
import { TypeOfDemand } from 'graphql/map/queries/get-suggestions';
import { isEmpty, isEqual, isNil, isNumber, isString, lte, toNumber, toString } from 'lodash';
import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Context, Fragment, createContext } from 'react';
import { getBreadcrumbListJsonLD } from 'seo/schema';
import { detectDeviceByUserAgent, parseSortParamFromStringToObject } from 'utils';

const SearchResult = dynamic(() => import('components/desktop/search-result'));
const MobileSearchResult = dynamic(() => import('components/mobile/search-result'));

const DEFAULT_LIMIT_CARD_ITEM = 28;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, params, query } = context;
  const { headers, cookies } = req;
  const authenticationJSONString = toString(cookies['authentication']);
  if (authenticationJSONString) {
    const { accessToken } = JSON.parse(authenticationJSONString) as AuthenticationModel;
    if (accessToken) {
      const authLink = createAuthenticationLink(accessToken);
      mainClient.setLink(ApolloLink.from([errorLink, authLink, mainLink]));
    }
  }
  const device = detectDeviceByUserAgent(headers['user-agent']);
  let pageData = null;
  const { ['ket-qua-tim-kiem']: searchResultPathname } = params ?? {};
  const { data: slugifyData } = await mainClient.query<SlugifyData, SlugifyVariables>({
    query: SLUGIFY,
    variables: {
      slug: isString(searchResultPathname) ? searchResultPathname : '',
    },
  });
  const { slugify } = slugifyData;

  if (slugify) {
    const {
      page,
      sort,
      search,
      areaFrom,
      areaTo,
      priceFrom,
      priceTo,
      hasAlley,
      widthFrom,
      widthTo,
      lengthFrom,
      lengthTo,
      direction,
      bedroomCountFrom,
      bedroomCountTo,
      toiletCountFrom,
      toiletCountTo,
      floorCountFrom,
      floorCountTo,
      postType,
    } = query;
    const { filters } = slugify ?? {};
    const { typeOfDemand, realEstateType, provinceId, districtId, wardId, streetId, area, price } =
      filters as any;
    const [{ data: getRealEstatesData }, { data: nearRealEstatesData }] = await Promise.all([
      mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
        query: GET_REAL_ESTATES,
        variables: {
          approvalStatus: ApprovalStatusEnumType.approved,
          postStatus: [PostStatusType.active],
          tradingStatus: [TradingStatus.trading],
          limit: DEFAULT_LIMIT_CARD_ITEM,
          page: isNaN(toNumber(page)) || lte(toNumber(page), 0) ? 1 : toNumber(page),
          sort:
            isString(sort) && !isEmpty(sort)
              ? parseSortParamFromStringToObject(sort)
              : { createdAt: -1 },
          searchString: isString(search) ? search : '',
          typeOfDemand,
          realEstateType,
          province: provinceId,
          district: districtId,
          ward: wardId,
          street: streetId,
          area: area
            ? { from: area.from, to: area.to }
            : (areaFrom && isNumber(toNumber(areaFrom))) || (areaTo && isNumber(toNumber(areaTo)))
            ? { from: toNumber(areaFrom), to: toNumber(areaTo) }
            : undefined,
          price: price
            ? { from: price.from, to: price.to }
            : (priceFrom && isNumber(toNumber(priceFrom))) ||
              (priceTo && isNumber(toNumber(priceTo)))
            ? {
                from: toNumber(priceFrom),
                to: toNumber(priceTo),
              }
            : undefined,
          hasAlley: isNil(hasAlley) ? undefined : isEqual(hasAlley, 'true'),
          width:
            (widthFrom && isNumber(toNumber(widthFrom))) || (widthTo && isNumber(toNumber(widthTo)))
              ? {
                  from: toNumber(widthFrom),
                  to: toNumber(widthTo),
                }
              : undefined,
          length:
            (lengthFrom && isNumber(toNumber(lengthFrom))) ||
            (lengthTo && isNumber(toNumber(lengthTo)))
              ? {
                  from: toNumber(lengthFrom),
                  to: toNumber(lengthTo),
                }
              : undefined,
          direction: direction ? ([direction] as Direction[]) : undefined,
          bedroomCount:
            (bedroomCountFrom && isNumber(toNumber(bedroomCountFrom))) ||
            (bedroomCountTo && isNumber(toNumber(bedroomCountTo)))
              ? {
                  from: toNumber(bedroomCountFrom),
                  to: toNumber(bedroomCountTo),
                }
              : undefined,
          toiletCount:
            (toiletCountFrom && isNumber(toNumber(toiletCountFrom))) ||
            (toiletCountTo && isNumber(toNumber(toiletCountTo)))
              ? {
                  from: toNumber(toiletCountFrom),
                  to: toNumber(toiletCountTo),
                }
              : undefined,
          floorCount:
            (floorCountFrom && isNumber(toNumber(floorCountFrom))) ||
            (floorCountTo && isNumber(toNumber(floorCountTo)))
              ? {
                  from: toNumber(floorCountFrom),
                  to: toNumber(floorCountTo),
                }
              : undefined,
          postType: postType && isNumber(toNumber(postType)) ? [toString(postType)] : undefined,
        },
      }),
      mainClient.query<NearRealEstatesData, NearRealEstatesVariables>({
        query: NEAR_REAL_ESTATES,
        variables: {
          isForSell: !isEqual(
            (slugifyData?.slugify?.filters as any)?.typeOfDemand,
            TypeOfDemand.ForRent,
          ),
          province: (slugifyData?.slugify?.filters as any).provinceId,
        },
      }),
    ]);
    const { getRealEstates: realEstatesWithPagination } = getRealEstatesData;
    const { nearRealEstates } = nearRealEstatesData;

    pageData = {
      slugify: slugify ?? null,
      realEstatesWithPagination: realEstatesWithPagination ?? null,
      nearRealEstates: nearRealEstates ?? null,
    };
  }

  return {
    props: {
      device,
      pageData,
    },
  };
};

export const SearchResultPageContext: Context<Omit<Props, 'device'>> = createContext({});

interface Props {
  device?: 'mobile' | 'desktop';
  pageData?: {
    slugify?: Slug | null;
    realEstatesWithPagination: ListRealEstate | null;
    nearRealEstates: StatisticRealEstate[] | null;
  } | null;
}

const SearchResultPage: NextPage = (props: Props) => {
  const { device, ...restProps } = props;
  const { pageData } = restProps;
  const { slugify } = pageData ?? {};
  const { seoMetaTitle, seoMetaDescription, seoMetaImage, slug, breadScrumbs } = slugify ?? {};
  const title = seoMetaTitle ?? DEFAULT_TITLE;
  const description = seoMetaDescription ?? DEFAULT_DESCRIPTION;
  const ogUrl = slug ? `${MAIN_DOMAIN}/${slug}` : MAIN_DOMAIN;
  const ogImage = seoMetaImage ?? DEFAULT_OG_IMAGE;
  const breadcrumbs = breadScrumbs ?? [];
  const breadcrumbListJsonLD = getBreadcrumbListJsonLD(breadcrumbs);

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
        <meta name='description' content={description} />
        <meta name='og:title' content={title} />
        <meta name='og:description' content={description} />
        <meta name='og:url' content={ogUrl} />
        <meta name='og:image' content={ogImage} />
        <title>{title}</title>
      </Head>
      <SearchResultPageContext.Provider value={restProps}>
        {isEqual(device, 'mobile') ? (
          slugify ? (
            <MobileSearchResult />
          ) : (
            <MobileError404 />
          )
        ) : isEqual(device, 'desktop') ? (
          slugify ? (
            <SearchResult />
          ) : (
            <Error404 />
          )
        ) : null}
      </SearchResultPageContext.Provider>
      <script
        key='breadcrumb-search-result-json-ld'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: breadcrumbListJsonLD }}
      />
    </Fragment>
  );
};

export default SearchResultPage;
