import { FileWithFullUrls, RealEstate } from './../../graphql/main/queries/get-real-estates';
import { Product, WithContext } from 'schema-dts';
import { DEFAULT_DESCRIPTION, DEFAULT_OG_IMAGE, MAIN_DOMAIN } from 'globalConstants';
import { upperFirst } from 'lodash';

export const getRealEstateJsonLD = (realEstate?: RealEstate | null) => {
  const { postTitle, postContent, fullSlug, mediaUrls, price: realEstatePrice } = realEstate ?? {};
  const url = fullSlug ? `${MAIN_DOMAIN}/${fullSlug}` : MAIN_DOMAIN;
  const description = postContent ? upperFirst(postContent.substring(0, 139)) : DEFAULT_DESCRIPTION;
  const price = realEstatePrice ? realEstatePrice : 'Thương lượng';

  const realEstateSchema: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product', //Todo
    url,
    description,
    name: postTitle,
    image: (mediaUrls?.[0] as FileWithFullUrls)?.originalUrl ?? `${MAIN_DOMAIN}${DEFAULT_OG_IMAGE}`,
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency: 'VND',
    },
  };

  return JSON.stringify(realEstateSchema);
};
