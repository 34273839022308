import { map } from 'lodash';
import { BreadcrumbList, WithContext } from 'schema-dts';
import { MAIN_DOMAIN } from 'globalConstants';
import { BreadScrumb } from '../../graphql/main/queries/slugify';

export const getBreadcrumbListJsonLD = (breadcrumbs?: BreadScrumb[]) => {
  const listItems = breadcrumbs
    ? map(breadcrumbs, (breadcrumb, breadcrumbIndex) => ({
        '@type': 'ListItem',
        position: breadcrumbIndex + 2,
        name: breadcrumb.tooltip,
        item: `${MAIN_DOMAIN}/${breadcrumb.slug}`,
      }))
    : [];
  const breadcrumbListSchema: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Trang chủ',
        item: MAIN_DOMAIN,
      },
      ...(listItems as any),
    ],
  };

  return JSON.stringify(breadcrumbListSchema);
};
